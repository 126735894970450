import request from "../../utils/request";

//用户登录
export const login = (data)=>{
    return  request({
        method:'POST',
        url:'/user/login',
        data
    })
}

//用户注册
export const register = (data)=>{ 
   return  request({
        method:'POST',
        url:'/user/register',
        data
    })
}

// export const checkUsernameExist = (account)=>{
//     return  request({
//          method:'POST',
//          url:`/user/checkUsernameExist/${account}`,
//      })
//  }

 //发送验证码
 export const sendVerifyCode = (codeType,phoneNumber)=>{
    return  request({
         method:'POST',
         url:`/user/sendVerifyCode/${codeType}/${phoneNumber}`,
     })
 }
