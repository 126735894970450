<template>
  <div class="login">
    <Header/>
    <Content/>
  </div>
</template>

<script>
import Header from './components/login-header.vue';
import Content from './components/login-content.vue';
export default {
    components:{
        Header,
        Content
    }

}
</script>

<style scoped>
.login{
    width: calc(100vw);
    min-width: 1440px;
    background-image: url(https://hwj-games-market.oss-cn-shanghai.aliyuncs.com/background/login_bg_mini.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
</style>